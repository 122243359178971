import { LightBox } from '@kontentino/ui';
import React, { FC, useMemo } from 'react';
import AccordionWidgetEmptyMessage from 'app/modules/posts/components/accordionWidget/AccordionWidgetEmptyMessage';
import CommentsListItem from 'app/modules/posts/modules/post/components/postCommunication/comments/commentsList/CommentsListItem';
import { isNeighborOfComment } from 'app/modules/posts/modules/post/components/postCommunication/utils/isNeighborOfComment';
import { useCommentsLightbox } from 'app/modules/posts/modules/post/components/postCommunication/comments/hooks/useCommentsLightbox';
import { Comment } from 'app/modules/comments/types';
import CommentsListItemPinnedWrapper from './comments/commentsList/commentsListItem/CommentsListItemPinnedWrapper';
import CommentsListItemContent from './comments/commentsList/CommentsListItemContent';
import { User } from 'types/User';
import { getColorVariant } from 'app/modules/posts/modules/post/components/postCommunication/utils/getColorVariant';

type Props = {
  comments: Comment[];
  enableRespondToApprovalRequest?: boolean;
  onCommentWorkflowsIsVisibleChange?: (
    comment: Comment,
  ) => ((isVisible: boolean) => void) | undefined;
  hideUserInteractiveComponents?: boolean;
  currentUser: Pick<User, 'role'>;
  emptyState?: React.ReactNode;
};

export const CommentsList: FC<Props> = ({
  comments,
  hideUserInteractiveComponents,
  onCommentWorkflowsIsVisibleChange,
  enableRespondToApprovalRequest,
  currentUser,
  emptyState,
}) => {
  const { lightbox, allImages } = useCommentsLightbox(comments);

  return (
    <>
      {comments.length === 0 && (
        <div className="tw-flex tw-flex-1 tw-items-center tw-justify-center">
          <AccordionWidgetEmptyMessage
            message={emptyState}
            variant="communication"
          />
        </div>
      )}
      {comments.map((comment, index) => {
        const colorVariant = getColorVariant({
          thread: comment.thread,
          role: currentUser.role,
        });

        const renderCommentListItem = () => (
          <CommentsListItem
            key={`comment-${comment.id}`}
            comment={comment}
            isDemo={comment.user.is_demo}
            hideAvatar={isNeighborOfComment(comment, comments[index - 1])}
            hideHeader={isNeighborOfComment(comment, comments[index + 1])}
            showUserCard={!hideUserInteractiveComponents}
            currentUser={currentUser}
            colorVariant={colorVariant}
          >
            <CommentsListItemContent
              comment={comment}
              onWorkflowsIsVisibleChange={onCommentWorkflowsIsVisibleChange?.(
                comment,
              )}
              enableRespondToApprovalRequest={enableRespondToApprovalRequest}
              lightbox={lightbox}
              allImages={allImages}
              hideUserInteractiveComponents={hideUserInteractiveComponents}
              colorVariant={colorVariant}
            />
          </CommentsListItem>
        );

        return comment.is_pinned ? (
          <CommentsListItemPinnedWrapper
            key={`comment-${comment.id}-pinned`}
            comment={comment}
          >
            {renderCommentListItem()}
          </CommentsListItemPinnedWrapper>
        ) : (
          renderCommentListItem()
        );
      })}
      {useMemo(
        () =>
          !!allImages.length && (
            <LightBox
              galleryId="comments-lightbox"
              dynamic
              dynamicEl={allImages}
              onInit={({ instance }) => (lightbox.current = instance)}
            />
          ),
        [allImages, lightbox],
      )}
    </>
  );
};
