import React, { createContext, FC, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import { queryKey } from 'constants/queryKey';
import { BrandHubApi } from 'app/modules/brandHub/api/brandHub';
import { TYPE_FACEBOOK } from '@kontentino/kontentino-constants/Pages';
import { z } from 'zod';
import ValidationScheme from 'app/constants/validationSchemes';
import { runIfFn } from 'app/utils/run-if-fn';
import { UseQueryResult } from 'react-query/types/react';
import { BrandProfile, WebCrawlResultSchema } from 'app/modules/brandHub/types';

interface BrandHubNewCreativeFormContextType {
  form: ReturnType<typeof useForm<BrandHubNewCreativeFormValues>>;
  brandHubProfiles: UseQueryResult<BrandProfile[], unknown>;
  initialValues?: Partial<BrandHubNewCreativeFormValues>;
  submit: () => void;
}

const BrandHubNewCreativeFormContext = createContext<
  BrandHubNewCreativeFormContextType | undefined
>(undefined);

type ProviderProps = {
  children:
    | React.ReactNode
    | ((state: BrandHubNewCreativeFormContextType) => React.ReactNode);
  onSubmit: (data: BrandHubNewCreativeFormValues) => void;
  initialValues?: Partial<BrandHubNewCreativeFormValues>;
};

const defaultValues = {
  pageType: TYPE_FACEBOOK,
  goal: '',
  creativeCount: 3,
  creativeLength: 'medium',
  language: '',
};

const formSchema = z
  .object({
    brandProfileId: z.string().optional(),
    toneOfVoice: z.string().trim().optional(),
    language: z.string().trim().optional().nullable(),
    pageType: z.number().int().optional(),
    goal: ValidationScheme.RequiredString(),
    webCrawl: WebCrawlResultSchema.optional().nullable(),
    creativeCount: z.number().int().positive('Must be a positive number'),
    creativeLength: ValidationScheme.RequiredString(),
  })
  .superRefine((data, ctx) => {
    if (!data.brandProfileId && !data.toneOfVoice) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Either brand voice or tone of voice must be selected',
        path: ['brandProfileId'],
      });
    }
  });

export type BrandHubNewCreativeFormValues = z.infer<typeof formSchema>;

export const BrandHubNewCreativeFormProvider: FC<ProviderProps> = ({
  children,
  onSubmit,
  initialValues,
}) => {
  const form = useForm<BrandHubNewCreativeFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...defaultValues,
      ...initialValues,
    },
  });

  const brandHubProfiles = useQuery(
    queryKey.brandHubProfiles(),
    BrandHubApi.getAllBrandProfiles,
  );

  const submit = form.handleSubmit((values) => onSubmit(values));

  const value = {
    form,
    brandHubProfiles,
    submit,
    initialValues,
  };

  return (
    <BrandHubNewCreativeFormContext.Provider value={value}>
      {runIfFn(children, value)}
    </BrandHubNewCreativeFormContext.Provider>
  );
};

export const useBrandHubNewCreativeForm = () => {
  const context = useContext(BrandHubNewCreativeFormContext);

  if (context === undefined) {
    throw new Error(
      'useBrandHubNewCreativeForm must be used within BrandHubNewCreativeFormProvider',
    );
  }
  return context;
};
