import { ModalRouteProps } from 'context/ModalRouter';
import { FC } from 'react';
import Modal from 'components/shared/Modal';
import DisconnectedProfilesList from 'app/modules/settings/modules/me/modules/authorization/components/DisconnectedProfilesList';
import { useTranslation } from 'react-i18next';

type Props = ModalRouteProps;

const DisconnectedProfilesModal: FC<Props> = ({ modalManager }) => {
  const { t } = useTranslation();

  return (
    <Modal modalManager={modalManager}>
      <Modal.Header title={t('disconnectedProfilesModal.title')} withDivider>
        {t('disconnectedProfilesModal.description')}
      </Modal.Header>
      <Modal.Content>
        <DisconnectedProfilesList />
      </Modal.Content>
    </Modal>
  );
};

export default DisconnectedProfilesModal;
