import React, { useState } from 'react';
import Modal from 'components/shared/Modal';
import ModalFooter from 'components/shared/modal/ModalFooter';
import { ModalRouteProps } from 'context/ModalRouter';
import { useMutation } from 'react-query';
import UserApi from 'api/user';
import Logger from 'utils/logger';
import { ApiClientError } from 'api/client';
import { Alert, Button, Icon } from '@kontentino/ui';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus';
import InviteUserForm, { InviteUserFormState } from './InviteUserForm';
import { useToast } from 'app/hooks/useToast';
import { InviteUserStatus } from 'types/User';
import { userRoles } from './userRoles';

const InviteUserModal = (props: ModalRouteProps) => {
  const { modalManager } = props;
  const toast = useToast();
  const [isFormValid, setIsFormValid] = useState(false);

  const assignProfilesMutation = useMutation(
    (params: { userId: number; pageIds: string[] }) =>
      UserApi.assignProfilesToUser(params),
    {
      onError: (error: ApiClientError) => {
        toast(
          'Failed to assign profiles. Please try assigning them manually later.',
          'warning',
        );
        Logger.error(error);
      },
    },
  );

  const assignGroupsMutation = useMutation(
    (params: { userId: number; profileGroupIds: string[] }) =>
      UserApi.assignProfileGroupsToUser(params),
    {
      onError: (error: ApiClientError) => {
        toast(
          'Failed to assign profile groups. Please try assigning them manually later.',
          'warning',
        );
        Logger.error(error);
      },
    },
  );

  const sendInvitationMutation = useMutation(
    (values: InviteUserFormState) => UserApi.sendInvitation(values),
    {
      onSuccess: (data, values) => {
        if (
          data.status === InviteUserStatus.Created ||
          data.status === InviteUserStatus.Restored
        ) {
          if (values.selectedProfiles && values.selectedProfiles.length > 0) {
            assignProfilesMutation.mutate({
              userId: data.id,
              pageIds: values.selectedProfiles.map(String),
            });
          }

          if (values.selectedGroups && values.selectedGroups.length > 0) {
            assignGroupsMutation.mutate({
              userId: data.id,
              profileGroupIds: values.selectedGroups.map(String),
            });
          }

          modalManager.close();
          toast(
            <div>
              <p>Invitation successfully sent</p>
              <p className="tw-mt-2">
                <strong>{data.name}</strong> has been sent an invitation to join
                Kontentino as {''}
                <strong>
                  {userRoles[data.role as keyof typeof userRoles]}
                </strong>
              </p>
            </div>,
            'success',
          );
        }
      },
      onError: (error: ApiClientError) => {
        Logger.error(error);
      },
    },
  );

  function onSubmit(values: InviteUserFormState) {
    sendInvitationMutation.mutate(values);
  }

  return (
    <Modal
      modalManager={modalManager}
      size="sm"
      dataName={{
        wrapper: 'invite-user-modal',
      }}
    >
      <Modal.Header title="Invite a team member" className="tw-pb-0" />
      <Modal.Content className="tw-py-4">
        {sendInvitationMutation.isError && (
          <Alert variant="danger" className="tw-mb-4">
            {sendInvitationMutation.error?.userMessage ??
              'Something went wrong. Unable to send the invitation. Please try again later.'}
          </Alert>
        )}

        <InviteUserForm
          onSubmit={onSubmit}
          isSubmitting={sendInvitationMutation.isLoading}
          onValidityChange={setIsFormValid}
          serverError={
            sendInvitationMutation.data?.status === InviteUserStatus.Existed
              ? 'A member with this email is already in your team.'
              : ''
          }
        />
      </Modal.Content>
      <ModalFooter withDivider className="tw-flex tw-justify-center">
        {sendInvitationMutation.data?.status === InviteUserStatus.Created ||
        sendInvitationMutation.data?.status === InviteUserStatus.Restored ? (
          <Button
            variant="secondary"
            onClick={() => modalManager.close()}
            className="tw-ml-auto"
          >
            Close
          </Button>
        ) : (
          <Button
            type="submit"
            form="invite-user-form"
            disabled={sendInvitationMutation.isLoading || !isFormValid}
            iconBefore={<Icon icon={faUserPlus} />}
            isLoading={sendInvitationMutation.isLoading}
            className="tw-w-full"
          >
            Invite a team member
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default InviteUserModal;
