import { FC } from 'react';
import Modal from 'components/shared/Modal';
import {
  Button,
  Field,
  Icon,
  Avatar,
  Select,
  SocialMediaIcon,
  SocialMediaIconProps,
  Alert,
} from '@kontentino/ui';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { BrandHubApi } from 'app/modules/brandHub/api/brandHub';
import { ApiClientError } from 'api/client';
import { useTranslation } from 'react-i18next';
import { useToast } from 'app/hooks/useToast';
import AIGenerateButton from 'app/modules/aiContent/components/AIGenerateButton';
import { queryKey } from 'constants/queryKey';
import { BrandProfile, WebCrawlResultSchema } from 'app/modules/brandHub/types';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';
import WebCrawlLinkField from 'app/modules/brandHub/components/WebCrawlLinkField';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons/faChartLine';
import PageTypeUtils from 'app/utils/pageType';
import { getYtLogoGuidelineSize } from 'app/utils/getytlogoguidelinesize';

const MAX_ANALYTICS_PROFILES = 5;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onBrandProfileSuggested: (brandProfile: BrandProfile) => void;
};

const analyticsProfileSchema = z.object({
  profileId: z.string(),
  pageType: z.number(),
});

const schema = z
  .object({
    webCrawl: WebCrawlResultSchema.optional().nullable(),
    analyticsProfiles: z.array(analyticsProfileSchema).optional().default([]),
  })
  .refine(
    (data) => {
      return data.webCrawl || data?.analyticsProfiles.length > 0;
    },
    {
      message: 'Either analytics profile(s) or website URL must be provided',
      path: ['_errors'],
    },
  );

type FormValues = z.infer<typeof schema>;

type FormErrorType = {
  _errors: {
    message: string;
    type: string;
  };
};

const BrandHubSuggestProfileModal: FC<Props> = ({
  isOpen,
  onClose,
  onBrandProfileSuggested,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { accountPlanType } = useSubscriptionInfo();
  const queryClient = useQueryClient();
  const analyticsProfiles = useQuery(queryKey.brandHubAnalyticsProfiles(), () =>
    BrandHubApi.getAnalyticsProfiles(),
  );

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      webCrawl: undefined,
      analyticsProfiles: [],
    },
  });

  const mutation = useMutation(BrandHubApi.suggestBrandProfile, {
    onSuccess: (brandProfile) => {
      onClose();
      onBrandProfileSuggested(brandProfile);
    },
    onError(e: ApiClientError) {
      toast(e?.userMessage ?? t('somethingWentWrong'), 'error');
    },
    onSettled() {
      queryClient.invalidateQueries(queryKey.aiContentOptions());
    },
  });

  function onSubmit({ webCrawl, analyticsProfiles }: FormValues) {
    mutation.mutate({
      bodyText: webCrawl?.bodyText,
      planType: accountPlanType,
      logo: webCrawl?.logo,
      analyticsProfiles,
    });
  }

  return (
    <Modal
      dataName={{
        closeButton: 'brand-hub_suggest-profile_close',
        wrapper: 'brand-hub_suggest-profile_modal',
      }}
      dataCy={{
        closeButton: 'brand-hub_suggest-profile_close',
        wrapper: 'brand-hub_suggest-profile_modal',
      }}
      classNames={{
        root: '!tw-z-[1002]',
      }}
      open={isOpen}
      onClose={onClose}
      isLoading={mutation.isLoading}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Modal.Header
          title={t('generateBrandProfileTitle')}
          subtitle={t('generateBrandProfileFromWebsiteDescription')}
        />

        <Modal.Content className="tw-space-y-4 tw-pb-4">
          {(form.formState.errors as FormErrorType)?._errors?.message && (
            <Alert
              variant="warning"
              data-cy="brand-hub_suggest-profile_warning"
            >
              {(form.formState.errors as FormErrorType)?._errors.message}
            </Alert>
          )}

          <Field.Group>
            <Field.Label required>
              <Icon icon={faChartLine} className="tw-mr-1" />
              {t('analyticsProfile')}
            </Field.Label>
            <Field.Caption>
              {t('analyticsProfilePostsWillBeUsedToGenerateBrandProfile')}
            </Field.Caption>
            <Controller
              control={form.control}
              name="analyticsProfiles"
              render={({ field }) => {
                const profileOptions =
                  analyticsProfiles.data?.edges.map(({ node }) => ({
                    value: node.id,
                    label: `${node.name} ${
                      node.fans ? `(${node.fans} ${t('fans')})` : ''
                    }`,
                    type: node._type,
                    picture: node.picture,
                  })) || [];

                return (
                  <div
                    data-name="brand-hub_suggest-profile_analytics-profile_select-wrapper"
                    data-cy="brand-hub_suggest-profile_analytics-profile_select-wrapper"
                  >
                    <Select
                      menuPortalTarget={document.body}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      styles={{
                        menuPortal: (styles) => ({
                          ...styles,
                          zIndex: 1010,
                        }),
                      }}
                      value={form.watch('analyticsProfiles')?.map((profile) => {
                        const profileOption = profileOptions.find(
                          (opt) => opt.value === profile.profileId,
                        );

                        if (!profileOption) {
                          return {
                            value: profile.profileId,
                            label: 'Unknown profile',
                            type: profile.pageType,
                            picture: undefined,
                          };
                        }

                        return {
                          value: profile.profileId,
                          label: profileOption.label,
                          type: profile.pageType,
                          picture: profileOption.picture,
                        };
                      })}
                      isClearable
                      placeholder={t('selectAnalyticsProfile')}
                      formatOptionLabel={(option) => (
                        <div className="tw-flex tw-items-center">
                          <div className="tw-relative">
                            <Avatar
                              size={26}
                              name={option.label}
                              src={option.picture || undefined}
                              className="tw-rounded-md"
                            />
                            <SocialMediaIcon
                              type={
                                PageTypeUtils.getName(
                                  option.type,
                                ) as SocialMediaIconProps['type']
                              }
                              size={getYtLogoGuidelineSize(12, option.type)}
                              className="tw-absolute tw-bottom-0 tw-right-0"
                            />
                          </div>
                          <span className="tw-ml-2">{option.label}</span>
                        </div>
                      )}
                      onChange={(selectedOptions) => {
                        if (selectedOptions.length > MAX_ANALYTICS_PROFILES) {
                          toast(
                            t('maxAnalyticsProfilesForBrandProfileReached'),
                            'warning',
                          );
                          const limitedOptions = selectedOptions.slice(
                            0,
                            MAX_ANALYTICS_PROFILES,
                          );
                          const analyticsProfiles = limitedOptions.map(
                            (option) => ({
                              profileId: option.value,
                              pageType: option.type,
                            }),
                          );
                          field.onChange(analyticsProfiles);
                          form.trigger();
                          return;
                        }

                        const analyticsProfiles = selectedOptions.map(
                          (option) => ({
                            profileId: option.value,
                            pageType: option.type,
                          }),
                        );
                        field.onChange(analyticsProfiles);
                        form.trigger();
                      }}
                      options={profileOptions}
                      isLoading={analyticsProfiles.isLoading}
                      isOptionDisabled={(option) => {
                        const selectedProfiles =
                          form.watch('analyticsProfiles') || [];
                        const isAlreadySelected = selectedProfiles.some(
                          (profile) => profile.profileId === option.value,
                        );
                        return (
                          !isAlreadySelected &&
                          selectedProfiles.length >= MAX_ANALYTICS_PROFILES
                        );
                      }}
                    />
                  </div>
                );
              }}
            />
          </Field.Group>

          <Field.Group>
            <Field.Label required>
              <Icon icon={faGlobe} className="tw-mr-1" />
              {t('webLink')}
            </Field.Label>
            <Controller
              control={form.control}
              name="webCrawl"
              render={({ field }) => (
                <WebCrawlLinkField
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);

                    form.trigger();
                  }}
                  placeholder={t('brandProfileWebsiteLinkPlaceholder')}
                  dataNamePrefix="brand-hub_suggest-profile_web-crawl-link"
                  autoFetch
                />
              )}
            />
            {!!form.formState.errors.webCrawl?.message && (
              <Field.Error>
                <>{form.formState.errors.webCrawl?.message}</>
              </Field.Error>
            )}
          </Field.Group>
        </Modal.Content>
        <Modal.Footer withDivider>
          <Button
            data-name="brand-hub_suggest-profile_close"
            onClick={onClose}
            variant="secondary"
          >
            {t('fillManually')}
          </Button>
          <AIGenerateButton
            type="button"
            data-name="brand-hub_suggest-profile_generate"
            onClick={form.handleSubmit(onSubmit)}
            isLoading={mutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default BrandHubSuggestProfileModal;
