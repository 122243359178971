import React, {
  createContext,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { PostCommunicationContent } from 'app/modules/posts/modules/post/components/postCommunication/types/postCommunicationContent';
import { runIfFn } from 'app/utils/run-if-fn';
import { PostCommunicationProps } from 'app/modules/posts/modules/post/components/PostCommunication';
import {
  Comment,
  CommentAction,
  CommentThread,
} from 'app/modules/comments/types';
import { UserStore } from 'modules/user/userReducer';
import { Editor } from '@tiptap/core';
import DomUtils from 'app/utils/dom';
import { CommentsConfig } from 'app/modules/comments/config';
import { useCommentActions } from 'app/modules/posts/modules/post/components/postCommunication/hooks/useCommentActions';

export type PostCommunicationUser = Pick<UserStore, 'avatar' | 'name' | 'role'>;

export type PostCommunicationProviderProps = PostCommunicationProps & {
  user: PostCommunicationUser;
  disableCommunicationActions?: boolean;
  children:
    | React.ReactNode
    | ((context: PostCommunicationContextState) => React.ReactNode);
  emptyState?: React.ReactNode;
};

export type PostCommunicationContextState = {
  allowedActiveContent: PostCommunicationContent[];
  activeContent: PostCommunicationContent;
  changeActiveContent: (content: PostCommunicationContent) => void;
  postId: number;
  enableRespondToApprovalRequest?: boolean;
  user: PostCommunicationUser;
  commentsListRef: React.RefObject<HTMLDivElement>;
  commentValue: string;
  setCommentValue: (value: string) => void;
  commentAction: CommentAction | null;
  comment: Comment | undefined;
  initReplyCommentAction: (comment: Comment) => void;
  initEditCommentAction: (comment: Comment) => void;
  initReworkCommentAction: (
    threadType: Exclude<CommentThread, 'pinned'>,
  ) => void;
  resetCommentAction: () => void;
  disableCommunicationActions?: boolean;
  setEditorRef: (editor: Editor) => void;
  scrollToActiveRequestComment: () => void;
  readyToTrackVisibility: boolean;
  setReadyToTrackVisibility: (value: boolean) => void;
  emptyState?: React.ReactNode;
};

export const PostCommunicationContext = createContext<
  PostCommunicationContextState | undefined
>(undefined);

export type PostCommunicationRef = {
  initReworkCommentAction: (
    threadType: Exclude<CommentThread, 'pinned'>,
  ) => void;
  initEditCommentAction: (comment: Comment) => void;
  initReplyCommentAction: (comment: Comment) => void;
  resetCommentAction: () => void;
};

const PostCommunicationProvider = forwardRef<
  PostCommunicationRef,
  PostCommunicationProviderProps
>((props, ref) => {
  const {
    user,
    disableCommunicationActions,
    postId,
    enableRespondToApprovalRequest,
  } = props;
  const [editorRef, setEditorRef] = useState<Editor | undefined>(undefined);
  const [commentValue, setCommentValue] = useState('');
  const commentsListRef = React.useRef<HTMLDivElement>(null);
  const [readyToTrackVisibility, setReadyToTrackVisibility] =
    useState<boolean>(false);

  const {
    allowedActiveContent,
    activeContent,
    resetCommentAction,
    initEditCommentAction,
    initReworkCommentAction,
    commentAction,
    initReplyCommentAction,
    changeActiveContent,
    comment,
  } = useCommentActions({
    editorRef,
    setCommentValue,
  });

  const scrollToActiveRequestComment = () => {
    if (commentsListRef.current) {
      const latestRequestButton =
        commentsListRef.current.querySelector<HTMLDivElement>(
          `[data-id="${CommentsConfig.ACTIVE_APPROVAL_REQUEST_DATA_ID}"]`,
        );

      if (latestRequestButton) {
        DomUtils.scrollParentToChild(
          commentsListRef.current,
          latestRequestButton,
        );
      }
    }
  };

  useImperativeHandle(ref, () => ({
    resetCommentAction,
    initReworkCommentAction,
    initEditCommentAction,
    initReplyCommentAction,
  }));

  const contextValue = {
    allowedActiveContent,
    activeContent,
    postId,
    enableRespondToApprovalRequest,
    commentAction,
    user,
    commentsListRef,
    commentValue,
    setCommentValue,
    comment,
    initReplyCommentAction,
    initEditCommentAction,
    initReworkCommentAction,
    resetCommentAction,
    changeActiveContent,
    disableCommunicationActions,
    setEditorRef,
    scrollToActiveRequestComment,
    readyToTrackVisibility,
    setReadyToTrackVisibility,
    emptyState: props.emptyState,
  };

  return (
    <PostCommunicationContext.Provider value={contextValue}>
      {runIfFn(props.children, contextValue)}
    </PostCommunicationContext.Provider>
  );
});

export const usePostCommunicationContext = () => {
  const context = React.useContext(PostCommunicationContext);

  if (!context) {
    throw new Error(
      'usePostCommunicationContext must be used within a PostCommunicationProvider',
    );
  }

  return context;
};

export default PostCommunicationProvider;
