import React, { FC, useState } from 'react';
import { PageData, ProfileGroup } from 'types/Page';
import { usePage } from 'modules/page/pageSelector';
import { Control, Controller } from 'react-hook-form';
import { InviteUserFormState } from './InviteUserForm';
import ProfileSelect from './ProfileSelect';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';

type Props = {
  control: Control<InviteUserFormState>;
  isDisabled?: boolean;
};

const AssignProfileSelects: FC<Props> = ({ control, isDisabled }) => {
  const { pages, profileGroups } = usePage();
  const [isProfilesMenuOpen, setIsProfilesMenuOpen] = useState(false);
  const [isGroupsMenuOpen, setIsGroupsMenuOpen] = useState(false);

  if (!pages || !profileGroups) return null;

  const profileOptions = pages.map((profile: PageData) => ({
    label: profile.name,
    value: profile.id,
    page: profile,
  }));

  const groupOptions = profileGroups
    .filter((group: ProfileGroup) => group.isEditable)
    .map((group: ProfileGroup) => ({
      label: group.name,
      value: group.id,
      page: {
        ...group,
        type: 0,
        isDemo: false,
        isGlobal: false,
      } as PageData,
    }));

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      {pages.length > 0 && (
        <Controller
          name="selectedProfiles"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ProfileSelect
              label="Assign Profiles"
              options={profileOptions}
              value={value || []}
              onChange={onChange}
              isDisabled={isDisabled}
              isMenuOpen={isProfilesMenuOpen}
              onMenuOpen={() => setIsProfilesMenuOpen(true)}
              onMenuClose={() => setIsProfilesMenuOpen(false)}
              noOptionsMessage="No profiles available"
              valueContainerLabel="Profiles selected"
            />
          )}
        />
      )}

      {profileGroups.length > 0 && (
        <UserPermissionGate scopes={UserPermission.PAGE_EDIT}>
          <Controller
            name="selectedGroups"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ProfileSelect
                label="Assign Profile Groups"
                options={groupOptions}
                value={value || []}
                onChange={onChange}
                isDisabled={isDisabled}
                isMenuOpen={isGroupsMenuOpen}
                onMenuOpen={() => setIsGroupsMenuOpen(true)}
                onMenuClose={() => setIsGroupsMenuOpen(false)}
                noOptionsMessage="No profile groups available"
                valueContainerLabel="Profile groups selected"
              />
            )}
          />
        </UserPermissionGate>
      )}
    </div>
  );
};

export default AssignProfileSelects;
